import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "../components";

export default function AboutPage() {
  return (
    <>
      <h1>
        <FormattedMessage id="notFound" defaultMessage="Not found" />
      </h1>

      <Link className="btn btn-link" href="/">
        <FormattedMessage id="homepage" defaultMessage="Homepage" />
      </Link>
    </>
  );
}
